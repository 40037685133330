import React from 'react';
// import logo from './logo.svg';
import './App.css';

function App() {
 return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} alt="logo" /> */}
        <h1>Hello World 🚀</h1>
        <h3>CI-CD Pipeline test demo!</h3>
      </header>
    </div>
 );
}

export default App;